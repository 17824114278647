.table-userinfo {
    /* text-align: right; */
    padding: 0px;
    margin: 0px;
    border-collapse: collapse;
    border: 0px;
}

.table-userid {
    border: 0px;
    padding: 0px;
    line-height: normal;
}

.table-balance {
    border: 0px;
    padding: 0px;
    line-height: normal;
    font-size: 11px;
}